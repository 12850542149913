import React from 'react'
import PropTypes from "prop-types";
import {devTag} from "./devTag";

import "./devItemStyles.scss"
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Markdown = ({text}) => (
    <ReactMarkdown
        children={text}
        remarkPlugins={[remarkGfm]}
        className="dev-item-text"
    />
)

const DevItem = ({ text, tag, done = false }) => {

  const doneClass = done ? "done" : ""
  if(done) {
    console.log(tag + " is done")
  }

  const tagEl = tag ? (
      <span className={`tag ${tag}`}>{tag}</span>
  ) : null

  return (
      <div className={`dev-item ${doneClass}`}>
        {tagEl}
        <Markdown text={text} />
      </div>
  )
}

export default DevItem

DevItem.propTypes = {
  text: PropTypes.string.isRequired,
  tag: PropTypes.oneOf([
      devTag.BUG,
      devTag.FEATURE,
      devTag.HEALTH
  ])
}