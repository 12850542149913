import React from "react"
import {RowType} from "./obj/TableData";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const isDebug = false
// const Cell = ({ cellData }) => {
//
//   console.log(cellData)
//   return (
//       <td dangerouslySetInnerHTML={{__html: cellData}} />
//   )
// }
const Row = ({ rowData }) => {

  if(!rowData) {
    console.error("Row component cannot render without rowData props");
    return null
  }
  // console.log(rowData)

  return (
      <tr>
      {rowData.cells.map((cell, cellKey) => {

        // console.log(cell)

        if(cell === "" || cell === "undefined") {
          return (<td/>)
          // return null
        }

        const cellArray = []
        if (Array.isArray(cell)){
          if (isDebug) {
            console.log(`cell is an array:`)
            console.log(cell)
          }
          cell.map((item, key) => {
            cellArray.push((
                <>
                  <ReactMarkdown children={item} remarkPlugins={[remarkGfm]} />
                </>
            ))
          })
          cell = cellArray
          if (isDebug)
            console.log(cell)
        }
        else {
          cell = (
              <ReactMarkdown children={cell} remarkPlugins={[remarkGfm]} />
          )
        }

        if(rowData.rowType === RowType.HEADER) {
          return (
              <th key={cellKey} >
                {cell}
              </th>
          )
        }
        else if(rowData.rowType === RowType.BODY) {
          return (
              <td key={cellKey} >
                {cell}
              </td>
          )
        }
        else if(rowData.rowType === RowType.FOOTER) {
          return (
              <td key={cellKey} >
                {cell}
              </td>
          )
        }
      })}
      </tr>
  )
}

const Table = ({ tableData }) => {

  if(!tableData) {
    console.error("Table component cannot render without tableData props");
    return null
  }
  // console.log(tableData)

  return (
      <table>
        <tbody>

        {tableData.rows.map((row, key) => {
          return (
              <Row rowData={row} key={key} />
          )}
        )}

        </tbody>
      </table>
  )
}



export default Table