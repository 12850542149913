import React from "react"
import { graphql } from "gatsby"
import { login, isAuthenticated } from "../utils/auth"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import LayoutDrawer from "../components/LayoutDrawer"
import Table from "../components/Table"
import Prompt from "../components/Prompt"
import DevItem from "../components/DevItem"
// import {devTag} from "../components/DevItem/devTag"
import Code from "../dangerousComponents/Code"
import CheckIcon from '@mui/icons-material/Check'

const shortCodes = { Code, CheckIcon, DevItem, Prompt, Table } // Provide common components here


const DocsTemplate = ({ data }) => {
  if(!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  const { mdx } = data
  const { frontmatter } = mdx

  return (
      <main>
        <title>{ mdx.frontmatter.title }</title>
        <LayoutDrawer sidebar>
          <h1>{frontmatter.title}</h1>
          <h2 className="h5">{frontmatter.caption}</h2>
          <small>{frontmatter.date}</small>
          <hr/>
          <MDXProvider components={ shortCodes }>
            <MDXRenderer frontmatter={ mdx.frontmatter }>
              { mdx.body }
            </MDXRenderer>
          </MDXProvider>
        </LayoutDrawer>
      </main>
  )
}

export default DocsTemplate

export const pageQuery = graphql`
    query ($id: String) {
        mdx(id: { eq: $id }) {
            id
            body
            frontmatter {
                title
                caption
                date(formatString: "MMMM DD, YYYY")
            }
        }
    }
`