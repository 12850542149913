import React from "react"

const Code = ({codeStr}) => {

  console.log(codeStr)

  return (
      <pre>
        <code dangerouslySetInnerHTML={{__html: codeStr}} />
      </pre>
  )
}

export default Code