import React from "react"
import PropTypes from "prop-types"

export const PromptType = {
  LINUX: "linux",
  MYSQL: "mysql",
}
const PromptLine = ({ text, decorator }) => (
    <>
      <b className="lowlight">{decorator} </b>{text}
    </>
)

const Prompt = ({ text, textArray, className, type }) => {

  let decorator
  switch (type) {
    case PromptType.LINUX:
      decorator = "$"
      break
    case PromptType.MYSQL:
      decorator = "mysql>"
      break
  }

  if(text) {
    return (
        <pre className={className}>
          <PromptLine text={text} decorator={decorator} />
      </pre>
    )
  }
  else if (textArray) {
    return (
        <pre className={className}>
          {textArray.map((item, key) => (
              <>
                <PromptLine text={item} decorator={decorator} key={key} /><br/>
              </>
          ))}
      </pre>
    )
  }
}

export default Prompt

Prompt.propTypes = {
  text: PropTypes.string,
  textArray: PropTypes.arrayOf(PropTypes.string.isRequired),
  className: PropTypes.string,
  type: PropTypes.oneOf([
    PromptType.LINUX,
    PromptType. MYSQL
  ])
}

Prompt.defaultProps = {
  className: ``,
  type: PromptType.LINUX
}